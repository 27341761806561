<template>
  <div class="col-12">
    <div class="card">
      <h5>Detalle del Registro </h5>
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-6">
          <label for="">C&oacute;digo</label>
          <InputText type="text" disabled v-model="laboratorio.id" />
        </div>
        <div class="field col-12 md:col-6">
          <label for="">Nombre </label>
          <InputText type="text" disabled v-model="laboratorio.nombre" />
        </div>
        <div class="field col-12 md:col-6">
          <label for="">C&oacute;digo Proveedor</label>
          <InputText type="text" disabled v-model="laboratorio.proveedor_id" />
        </div>
        <div class="field col-12 md:col-6">
          <label for="">Nombre Proveedor</label>
          <InputText type="text" disabled v-model="laboratorio.nombre_proveedor" />
        </div>
        <div class="field col-12 md:col-4">
          <label for="">Estado</label>
          <InputText type="text" disabled v-model="texto_estado" />
        </div>
        <div class="field col-12 md:col-4">
          <label for="">Fecha Creaci&oacute;n</label>
          <InputText type="text" disabled v-model="laboratorio.created_at" />
        </div>
        <div class="field col-12 md:col-4">
          <label for="">Fecha Actualizaci&oacute;n</label>
          <InputText type="text" disabled v-model="laboratorio.updated_at" />
        </div>
        <div class="field col-12 md:col-1">
          <Button @click="goBack">
            <i class="pi pi-arrow-circle-left"></i>
            &nbsp;ATR&Aacute;S
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import LaboratorioService from '@/service/LaboratorioService';
export default {
  data() {
    return {
      laboratorio: {},
      texto_estado : '',
      idLaboratorio: this.$route.params.id,
    };
  },
  laboratorioService: null,
  created() {
    this.laboratorioService = new LaboratorioService();
  },
  mounted() {
    this.laboratorioService.showLab(this.idLaboratorio).then((data) => {
      this.laboratorio = data;
      this.texto_estado = this.textoEstado(data.estado);
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    textoEstado(estado) {
      return estado == 1 ? "Activo" : "Inactivo";
    },
  },
};
</script>